@charset "UTF-8";
@use "settings" as s;

$color: #000;
$linkmark: #29a9e0;
$key: #00237d;
$txt: #5c5c5c;

html,body{
	height: 100%;
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 400;
}


/*! purgecss start ignore */


//header
.header{
	position: relative;
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
	background-color: #fff;
	padding-left: 34px;
	z-index: 100;
	@include s.mq(w1240){
		justify-content: space-between;
		padding-right: 34px;
	}
	@include s.mq(w587){
		padding: 0 20px;
	}
	&__scroll{
		position: fixed;
		//background: rgba(0,0,0,.3);
		// background-image: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05) 23%, rgba(0, 0, 0, 0.7));
		// backdrop-filter: blur(5px);
		// padding: 10px 26px 10px 26px;
		animation: header-down 0.5s forwards;
		// @include s.mq(w767){
		// 	padding: 10px 10px 0;
		// }
	}
	@keyframes header-down{
		from {
		opacity: 0;
		  transform: translateY(-170px);
		}
		to {
			opacity: 1;
		  transform: translateY(0);
		}
	  }
}
.nav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	margin-left: 46px;
	// @include s.mq(w1550){
	// 	margin-left: 30px;
	// }
	@include s.mq(w1240){
		display: none;
		background: #fff;
		position: absolute;
		top: 80px;
		left: 0;
		width: 100%;
		margin-left: 0;
		// display: block;
		padding: 0 20px 20px 20px;
	}
}
.gnav{
	&__list{
		display: flex;
		align-items: center;
		@include s.mq(w1240){
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.menu-item + .menu-item{
		margin-left: 2em;
		// @include s.mq(w1600){
		// 	margin-left: 1em;
		// }
		@include s.mq(w1240){
			margin-left: 0;
			border-top: 1px solid #ccc;
		}
	}
	.menu-item{
		@include s.mq(w1240){
			width: 100%;
		}
		a{
			font-size: 1.4rem;
			font-weight: 700;
			color: #000;
			@include s.mq(w1240){
				display: block;
				padding: 20px 0;
			}
		}
	}
	&__melmaga{
		a{
			&::before{
				content: url(../img/ico-mail.svg);
				display: inline-block;
				margin-right: 3px;
				transform: translateY(2px);
			}
			// background: url(../img/ico-mail.svg) no-repeat left 4px;
			//padding-left: 23px;
		}
	}
}

.utlnav{
	// position: absolute;
	// right: 0;
	&__list{
		display: flex;
		@include s.mq(w1240){
			flex-direction: column;
		}
	}
	&__link{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 264px;
		height: 80px;
		font-size: 1.4rem;
		font-weight: 500;
		color: #fff;
		background: $key;
		&::after{
			content: url(../img/ico-link.svg);
			margin-left: 3px;
		}
		@include s.mq(w1240){
			width: 100%;
			height: 60px;
			margin-top: 20px;
		}
		&--db{
			background: #b8d9ff;
			color: #000;
			width: 224px;
			&::after{
				content: url(../img/ico-link-db.svg);
				margin-left: 3px;
			}
			@include s.mq(w1240){
				width: 100%;
				height: 60px;
				margin-top: 30px;
			}
		}
	}
}

.menu{
	display: none;
	cursor: pointer;
	@include s.mq(w1240){
		display: block;
	}
	&__border{
		display: block;
		width: 40px;
		height: 1px;
		background: #000;
		transition: all .2s;
	}
	&__border + &__border{
		margin-top: 10px;
	}
	&.active {
		.menu__border:nth-child(1) {
			transform: translate(0, 11px) rotate(45deg);
		}
		.menu__border:nth-child(2){
			opacity: 0;
		}
		.menu__border:nth-child(3) {
			transform: translate(0px, -11px) rotate(-45deg);
		}
	}
}

//kv

.kv{
	height: 417px;
	background: url(../img/kv@2x.png) no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	&__title{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 372px;
		height: 84px;
		font-size: 4.8rem;
		font-weight: 700;
		color: $key;
		background: rgba(255,255,255,.6);
	}
}


//container

.container{
	display: flex;
	gap: 30px;
	width: 100%;
	max-width: 1570px;
	margin: auto;
	@include s.mq(w1550){
		padding: 0 20px;
	}
	@include s.mq(w980){
		display: block;
	}
	@include s.mq(w587){
		margin-top: 52px;
	}
}
.maincol{
	width: 100%;
	max-width: 1170px;
}

//sidebar

.sidebar{
	width: 100%;
	max-width: 370px;
	@include s.mq(w1280){
		max-width: 350px;
	}
	@include s.mq(w980){
		max-width: 100%;
		margin-top: 60px;
	}
	&__head{
		background: #385296;
		font-size: 2rem;
		font-weight: 700;
		color: #fff;
		display: flex;
		align-items: center;
		height: 55px;
		padding-left: 17px;
	}
	&__box{
		background: #fff;
		padding: 19px 19px 36px 19px;
	}
	&__list{
		li + li{
			margin-top: 1.2em;
		}
		li{
			text-align: left;
			a{
				font-size: 1.6rem;
				font-weight: 500;
				color: #333;
				span{
					display: inline-block;
					width: 26px;
					height: 26px;
					border-radius: 26px;
					line-height: 26px;
					text-align: center;
					background: #cbccce;
					color: #fff;
					font-size: 1.3rem;
					margin-left: 3px;
				}
			}
		}
	}
	// .cat-item + .cat-item{
	// 	margin-top: 1.2em;
	// }
	// .cat-item{
	// 	a{
	// 		font-size: 1.6rem;
	// 		font-weight: 500;
	// 		color: #333;
	// 		span{
	// 			display: inline-block;
	// 			width: 26px;
	// 			height: 26px;
	// 			border-radius: 26px;
	// 			line-height: 26px;
	// 			text-align: center;
	// 			background: #cbccce;
	// 			color: #fff;
	// 			font-size: 1.3rem;
	// 			margin-left: 3px;
	// 		}
	// 	}
	// }
	.mailmag{
		margin-top: 22px;
		img{
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
			border-radius: 6px;
		}
	}
	.search{
		margin-top: 22px;
		#search{
			position: relative;
		}
		&__input{
			width: 100%;
			height: 54px;
			padding: 12px 12px 12px 15px;
			border-radius: 6px;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
			background-color: #fff;
			border: none;
			font-size: 1.8rem;
		}
		&__btn{
			border: none;
			background: transparent;
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translateY(-50%);
			width: 29px;
			height: 29px;
			background-image: url(../img/ico-search.svg);
			background-repeat: no-repeat;
			background-size: 29px;
			span{
				position: absolute;
				width: 1px;
				height: 1px;
				padding: 0;
				margin: -1px;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				border: 0;
			}
		}
	}
	// .cat{
	// 	margin-top: 22px;
	// }
	.popular{
		margin-top: 30px;
		&__entry{
			display: flex;
			flex-direction: row;
			gap: 9px;
			margin-bottom: 0;
		}
		&__txt{
			margin-top: 0;
			// max-width: 233px;
			// @include s.mq(w1280){
			// 	max-width: 213px;
			// }
			// @include s.mq(w980){
			// 	max-width: 100%;
			// }
		}
		&__title{
			font-size: 1.4rem;
			font-weight: 700;
			line-height: calc(20 / 14);
			a{
				color: #000;
			}
		}
		.date{
			display: block;
			font-size: 1.2rem;
			margin-top: 7px;
		}
		&__img{
			width: 90px;
			height: 83px;
			order: -1;
			flex-shrink: 0;
			img{
				width: 90px;
				height: 83px;
				object-fit: cover;
			}
		}
	}
	.popular__entry + .popular__entry{
		margin-top: 16px;
	}
	.archives{
		margin-top: 30px;
	}
	.taglist{
		margin-top: 30px;
		// .sidebar__box{
		// 	display: flex;
		// 	flex-wrap: wrap;
		// }

		.tag{
			&__item{
				margin: 0 8px 8px 0;
			}
			
			//display: block;
			&__link{
				//display: inline-block;
			}
		}
		.tag__item + .tag__item{
			margin-left: 0;
		}
	}
}


//footer

.pagetop{
	display: block;
	text-align: center;
	margin-top: 80px;
	img{
		width: auto;
	}
	span{
		display: block;
		margin-top: 10px;
		font-size: 1.4rem;
		color: #000;
		font-weight: 700;
	}
}

.footer{
	margin-top: 50px;
	.bnr-area{
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff9eb;
		padding: 34px 20px;
		ul{
			width: 1380px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 0 !important;
			@include s.mq(w1380){
				width: 543px;
				flex-wrap: wrap;
				li{
					margin-bottom: 15px;
				}
				li:last-child{
					margin-bottom: 0;
				}
			}
			@include s.mq(w670){
				width: 100%;
				flex-wrap: wrap;
				li{
					width: 48%;
					img{
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
	.link-area{
		background: #fff5db;
		padding: 25px 20px 100px 20px;
		ul{
			display: flex;
			justify-content: center;
			@include s.mq(w670){
				flex-wrap: wrap;
			}

			li + li{
				margin-left: 1em;
			}
			li{
				font-size: 1.2rem;
				padding: 0 10px 0 15px;
				background: url(../img/arw_right_gray.png) no-repeat left center;
				@include s.mq(w670){
					margin-bottom: 1em;
				}
			}
			a{
				color: #333;
			}
		}
		small{
			font-size: 1.2rem;
			display: block;
			text-align: center;
			margin-top: 40px;
			color: #767676;
		}
	}
	&__notice{
		font-size: 1.4rem;
		line-height: calc(24 / 14);
		margin-top: 3em;
		display: flex;
		justify-content: center;
		color: #333;
	}
}

//common

.new{
	margin-top: 70px;
	&__container{
		display: grid;
		gap: 30px;
		grid-template-columns: 1fr 1fr 1fr;
		margin-top: 33px;
		@include s.mq(w1080){
			grid-template-columns: 1fr 1fr;
		}
	}
}
.archive, .search, .page{
	.new{
		margin-top: 0;
	}
}

.entry{
	display: flex;
	flex-direction: column;
	position: relative;
	&__txt{
		margin-top: 11px;
	}
	&__title{
		font-size: 1.6rem;
		font-weight: 700;
		line-height: calc(24 / 16);
		text-align: left;
		a{
			color: #000;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	&__body{
		margin-top: 13px;
		color: $txt;
		font-size: 1.4rem;
		line-height: calc(24 / 14);
	}
	&__img{
		order: -1;
		overflow: hidden;	
		a{
			display: block;
			position: relative;
			width: 100%;
			padding-top: 66.6666%;
			// display: block;
			// width: 100%;
			// aspect-ratio: 3 / 2;
			img{
				display: block;
				transition: all .2s;
				// border-radius: 6px;
				width: 100%;
				height: 100%;
				object-fit: cover;
				position: absolute;
				top: 0;
				// left: 0;
				// width: 100%;
				// height: 100%;
				// object-fit: cover;
			}
			&:hover{
				img{
					transform: scale(110%);
				}
			}
		}
	}
}
.postdate{
	display: block;
	color: $txt;
	font-size: 1.2rem;
	margin-top: 15px;
}

.pickup{
	&__badge{
		background: #FF9600;
		font-size: 1.2rem;
		font-weight: 700;
		line-height: 1;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 62px;
		height: 62px;
		border-radius: 62px;
		position: absolute;
		top: 7px;
		left: 8px;
		@include s.mq(w587){
			width: 42px;
			height: 42px;
			border-radius: 42px;
			font-size: 1rem;
			top: -7px;
			left: -7px;
		}
		&--1st{
			min-width: 92px;
			min-height: 92px;
			border-radius: 92px;
			font-size: 1.8rem;
			position: static;
			order: -1;
			@include s.mq(w587){
				min-width: 62px;
				min-height: 62px;
				border-radius: 62px;
				font-size: 1.4rem;
			}
		}
	}
}
.meta{
	margin-top: 22px;
	&__row{
		display: flex;
		align-items: center;
		font-size: 1.4rem;
		font-weight: 700;
		padding: 13px 0;
		border-top: 1px dotted #707070;
	}
	&__title{
		white-space: nowrap;
	}
	&__link{
		color: #000;
	}
	&__data{
		ul{
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
		}
		a{
			color: #000;
		}
	}
}

.tag{
	&__item{
		display: inline-block;
	}
	&__link{
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.4rem;
		font-weight: 400;
		color: #434343;
		padding: 0 12px;
		height: 25px;
		//text-align: center;
		background: #fff;
		border: 1px solid #d1d1d1;
		border-radius: 3px;
	}
}
// .tag__item + .tag__item{
// 	margin-left: 8px;
// }


.head1{
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 1.5;
	color: #000;
	border-bottom: 4px solid $key;
	padding-bottom: 10px;
}


.pagenavi{
	display: flex;
	justify-content: center;
	margin-top: 100px;
	.wp-pagenavi{
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 14px;
	}
	.current{
		width: 28px;
		height: 28px;
		line-height: 27px;
		border-radius: 14px;
		background: #00237d;
		color: #fff;
		font-size: 1.6rem;
		font-weight: 700;
		text-align: center;
	}
	.page{
		font-size: 1.6rem;
		font-weight: 700;
		color: #00237d;
		transform: translateY(-1px);
	}
	.previouspostslink,
	.nextpostslink {
		display: inline-block;
		font-size: 0;
		line-height: 0;
		color: transparent;
		width: 6px;
		height: 10px;
	}
	.previouspostslink{
		background: url(../img/ico-pagenavi-left.svg) no-repeat;
	}
	.nextpostslink{
		background: url(../img/ico-pagenavi-right.svg) no-repeat;
	}
}

/*! purgecss end ignore */


