@charset "UTF-8";
@use "_reset";
@use "settings" as s;
@use "user_reset";
@use "common";

.home{
	.container{
		margin-top: 82px;
	}
}

.pickup1st{
	display: flex;
	gap: 30px;
	@include s.mq(w1280){
		flex-direction: column;
	}
	&__header{
		display: flex;
		gap: 12px;
		align-items: center;
		&-txt{
			display: flex;
			flex-direction: column-reverse;
		}
	}
	&__title{
		font-size: 2.4rem;
		font-weight: 700;
		line-height: calc(34 / 24);
		margin-top: 5px;
	}
	.postdate{
		color: #000;
		margin-top: 0;
	}
	&__txt{
		width: 100%;
		max-width: 470px;
		@include s.mq(w1280){
			max-width: 100%;
		}
	}
	&__body{
		margin-top: 27px;
		p{
			font-size: 1.6rem;
			line-height: calc(31 / 16);
		}
	}
	&__img{
		width: 100%;
		max-width: 670px;
		@include s.mq(w1280){
			max-width: 100%;
		}
	}
}

.pickup2nd{
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	margin-top: 48px;
	// @include s.mq(w587){
	// 	display: block;
	// }
}



//insta
.insta{
	margin-top: 8vw;
	@include s.mq(w900){
		margin-top: 12vw;
	}
	@include s.mq(w587){
		margin-top: 20vw;
	}
	&__container{
		margin-top: 2em;
		@include s.mq(w587){
			margin-top: 0;
		}
	}
}

